@use 'fonts';
@use 'libs/styles/responsive';

*,
*::before,
*::after {
  @include fonts.nice-fonts;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  font-family: fonts.$font-Onest, Arial, Helvetica, sans-serif;
  font-style: normal;
}

h1,
.headline1 {
  font-weight: fonts.$fw-bold;
  font-size: 40px;
  margin-bottom: 0.5em;
  line-height: 1.1;

  @include responsive.target(lg) {
    font-size: 54px;
    word-break: auto-phrase;
  }

  @include responsive.target(xl, xxl) {
    font-size: 70px;
    word-break: auto-phrase;
  }
}

h2,
.headline2 {
  font-weight: fonts.$fw-bold;
  font-size: 25px;
  margin-bottom: 0.5em;
  line-height: 1.1;

  @include responsive.target(lg) {
    font-size: 32px;
    word-break: auto-phrase;
  }

  @include responsive.target(xl, xxl) {
    font-size: 40px;
    word-break: auto-phrase;
  }
}

p {
  font-size: 16px;
  line-height: 1.375;
  margin-bottom: 1.25em;

  @include responsive.target(lg) {
    font-size: 18px;
  }

  @include responsive.target(xl, xxl) {
    font-size: 22px;
  }
}

a {
  color: inherit;
  text-underline-offset: 5px;
}

strong {
  font-weight: fonts.$fw-bold;
}


.link {
  display: inline-block;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &.link--arrow {
    position: relative;
    text-transform: uppercase;
    padding-right: 33px;
    font-size: 14px;
    letter-spacing: 0.2em;
    font-weight: fonts.$fw-medium;
    line-height: 1.333;
    text-decoration: none;
    color: inherit;

    &::after {
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      content: " ";
      height: 23px;
      width: 23px;
      background: url('/static/media/icons/icon-cta-arrow-right.svg') 0 0 no-repeat;
      background-size: contain;
    }

    @include responsive.target(xl, xxl) {
      font-size: 16px;
      padding-right: 46px;

      &::after {
        height: 31px;
        width: 31px;
      }
    }
  }

  &.link--white,
  .page-wrapper--green & {
    &::after {
      filter: invert(1);
    }
  }
}