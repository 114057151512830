@use 'config';

#intro-animation {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: config.$color-gray-footer;
    z-index: 9;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;

    &.hide {
        transform: translateY(100%);
    }
}

.intro-animation__logo-container {
    position: relative;
    overflow: hidden;
    transition: transform 0.5s ease-in-out;

    &::before {
        position: absolute;
        content: " ";
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: config.$color-white;
        transform: translateY(0);
        transition: transform 0.5s ease-in-out;
    }

    .reveal & {
        &::before {
            transform: translateY(-100%);
        }
    }

    .hide & {
        transform: translateY(-82vw);
    }
}

.intro-animation__logo {
    max-width: 300px;
    width: 55vw;
    filter: grayscale(0);
    transition: filter 0.5s ease-in-out;

    .reveal & {
        filter: grayscale(1);
    }
}