@use 'config';
@use 'fonts';
@use 'libs/styles/responsive';

.header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 90px;
    background-color: config.$color-white;
    box-shadow: 3px 3px 6px rgba(config.$color-black, 0.16);
    transition: box-shadow 0.25s ease-in-out;

    @include responsive.target(xl, xxl) {
        padding: 35px 80px;
        height: auto;
    }
}

.header__logo {
    position: relative;
    z-index: 1;
    font-weight: fonts.$fw-bold;
    font-size: 18px;
    line-height: 1.111;
    text-decoration: none;
    text-transform: uppercase;
    color: config.$color-black;

    @include responsive.target(xl) {
        font-size: 24px;
    }

    @include responsive.target(xxl) {
        font-size: 32px;
    }
}

.header__nav-toggler {
    position: relative;
    z-index: 1;
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 10px 0;
    height: 35px;
    width: 35px;
    box-sizing: content-box;
    cursor: pointer;

    @include responsive.target(lg, xxl) {
        display: none;
    }

    img {
        width: 100%;
        opacity: 1;
        transition: opacity 0.25s ease-in-out;

        .header--nav-open & {
            opacity: 0;
        }
    }

    &:before,
    &:after {
        position: absolute;
        left: 22px;
        top: 11px;
        content: ' ';
        height: 33px;
        width: 1px;
        background-color: #333;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;

        .header--nav-open & {
            opacity: 1;
        }
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.header__nav {
    position: absolute;
    flex-basis: 100%;
    text-align: center;
    padding: 30px 0 65px;
    top: 100%;
    left: 0;
    right: 0;
    background-color: config.$color-white;
    box-shadow: 0px 3px 3px rgba(config.$color-black, 0.16);
    transform: translateY(-100%);
    transition: transform 0.25s ease-in-out;

    .header--nav-open & {
        transform: translateY(0);
    }

    @include responsive.target(lg, xxl) {
        position: relative;
        transform: translateY(0);
        flex-basis: auto;
        left: auto;
        top: auto;
        right: auto;
        bottom: auto;
        padding: 0;
        box-shadow: none;
        align-self: baseline;
        transition: none;
    }
}

.header__nav__list {
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    gap: 30px;

    .header--nav-open & {
        opacity: 1;
        visibility: visible;
    }

    @include responsive.target(lg, xxl) {
        opacity: 1;
        visibility: visible;
        flex-direction: row;
    }
}

.header__nav__link {
    position: relative;
    letter-spacing: 0.15em;
    font-size: 14px;
    line-height: 1.28;
    text-transform: uppercase;
    text-decoration: none;
    color: config.$color-black;

    @include responsive.target(xl, xxl) {
        font-size: 16px;
    }

    &.header__nav__link--active {
        &::after {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &:hover:not(.header__nav__link--active) {
        &::after {
            opacity: 0.45;
            transform: translateY(0);
        }
    }

    &::after {
        position: absolute;
        display: block;
        content: " ";
        left: 0;
        right: 0;
        height: 3px;
        background: url('/static/media/wave-form-black.svg') 0 0 repeat-x;
        background-size: auto 100%;
        opacity: 0;
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        transform: translateY(2px);
    }
}