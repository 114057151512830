@use 'config';

html,
body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
}

.page-wrapper {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &.page-wrapper--green {
        background-color: config.$color-green-1;
        color: config.$color-white;
    }

    &.page-wrapper--blue {
        background-color: config.$color-blue-1;
        color: config.$color-white;
    }

    &.page-wrapper--red {
        background-color: config.$color-red-1;
        color: config.$color-white;
    }
}

.header,
.footer {
    flex-grow: 0;
    flex-shrink: 0;
}

.page-body {
    flex-grow: 1;
}

img {
    display: block;
}


@mixin niceScrollbar() {
    -ms-overflow-style: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: config.$color-green-2 config.$color-gray-footer;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
    }

    &::-webkit-scrollbar-corner {
        background-color: config.$color-gray-footer;
    }

    &::-webkit-scrollbar-track {
        background-color: config.$color-gray-footer;
    }

    &::-webkit-scrollbar-thumb {
        background-color: config.$color-green-2;
    }
}