@use 'libs/styles/responsive';
@use 'config';

.contact {
    padding: 20px;
    margin: auto;

    @include responsive.target(lg, xxl) {
        padding: 80px;
        display: flex;
        flex-direction: row;
        gap: 80px;
    }

    @include responsive.target(xl, xxl) {
        gap: 120px;
    }

    @media screen and (min-width: 1600px) {
        max-width: config.$max-content-width;
        padding: 80px 0;
    }
}

.contact__form {
    button[type="submit"] {
        align-self: flex-end;
    }

    @include responsive.target(lg, xxl) {
        order: 1;
        width: 50%;
    }
}


.contact__intro {
    @include responsive.target(lg, xxl) {
        display: flex;
        align-items: center;
        order: 2;
        width: 50%;
    }
}