@use 'config';
@use 'libs/styles/responsive';
@use 'app/styles/common';

.mosaic-wrapper {
    background-color: config.$color-gray-footer;
}

.mosaic {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;

    @include responsive.target(lg, xxl) {
        position: relative;
        margin: auto;
        max-width: 2000px;
        flex-direction: row;
        padding: 0;
    }
}

.mosaic__item {
    border-top: 1px solid config.$color-black;
    padding: 20px 0;

    &.mosaic__item--main {
        border-top: none;
    }

    &:last-child {
        border-bottom: 1px solid config.$color-black;
    }

    @include responsive.target(lg, xxl) {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: none !important;
        padding: 0;
        aspect-ratio: 1 / 2;

        &:nth-child(2n) {
            flex-direction: column-reverse;
        }
    }
}

.mosaic__item__content {
    position: relative;

    &::after {
        position: absolute;
        right: 0;
        top: 0;
        content: " ";
        height: 23px;
        width: 23px;
        background: url('/static/media/icons/icon-cta-arrow-right.svg') 0 0 no-repeat;
        background-size: contain;
        pointer-events: none;
    }

    .mosaic__item--main & {
        text-align: center;

        @include responsive.target(xs, md) {
            text-align: left;
            padding-right: 0;
        }

        @include responsive.target(lg, xxl) {
            justify-content: center;
        }

        &::after {
            display: none;
        }
    }

    .mosaic__item--open & {
        &::after {
            transform: rotate(90deg);
        }
    }

    @include responsive.target(lg, xxl) {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 40px;
        overflow-y: auto;
        @include common.niceScrollbar;

        &::after {
            display: none;
        }
    }
}

.mosaic__item__topline {
    padding-right: 40px;
    margin-bottom: 0.5em;
    font-size: 14px;

    @include responsive.target(lg, xxl) {
        padding-right: 0;
    }
}

.mosaic__item__headline {
    padding-right: 40px;
    margin-bottom: 0;

    .mosaic__item--main & {
        padding-right: 0;
    }

    @include responsive.target(lg, xxl) {
        padding-right: 0;
        hyphens: auto;
        word-break: break-word;

        .mosaic__item--main & {
            font-size: 40px;
            hyphens: none;
        }
    }
}

.mosaic__item__text {
    display: none;
    margin-bottom: 0;
    margin-top: 0.5em;

    .mosaic__item--open & {
        display: block;
    }

    @include responsive.target(lg, xxl) {
        display: block;
    }
}

.mosaic__item__image {
    display: none;
    width: 100%;
    margin-top: 20px;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;

    .mosaic__item--main & {
        display: none;
    }

    .mosaic__item--open & {
        display: block;
    }

    @include responsive.target(lg, xxl) {
        display: block !important;
        flex: 1;
        margin-top: 0;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }

    &.reveal {
        opacity: 0;
    }
}