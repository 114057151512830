@use 'config';
@use 'libs/styles/responsive';

.centered-call-to-action {
    text-align: center;

    a {
        position: relative;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 11px 60px;
        margin-bottom: 75px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 23px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.2em;
        color: config.$color-green-2;
        background-color: config.$color-white;
        box-shadow: 2px 5px 10px rgba(config.$color-black, 0.005);
        transition: box-shadow 0.15s ease-out;

        svg {
            height: 23px;
            width: 23px;
        }

        @include responsive.target(xl, xxl) {
            font-size: 14px;
            padding: 25px 48px;
            border-radius: 51px;
            gap: 15px;
            margin-bottom: 125px;

            svg {
                height: 31px;
                width: 31px;
            }
        }

        &:hover {
            box-shadow: 2px 5px 10px rgba(config.$color-black, 0.25);
        }
    }
}