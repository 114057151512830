@use 'config';
@use 'libs/styles/responsive';

.content-grid {
    padding: 0 20px 50px;

    @include responsive.target(xl, xxl) {
        padding: 0 80px 150px;
    }

    @media screen and (min-width: 1600px) {
        max-width: config.$max-content-width;
        margin: auto;
        padding: 0 0 150px;
    }
}

.content-grid__row {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:not(:last-child) {
        margin-bottom: 60px;
    }

    @include responsive.target(md, xxl) {
        flex-direction: row;
        gap: unset;
        margin-bottom: 0 !important;

        .content-grid__row__visual,
        .content-grid__row__text {
            flex: 0 0 50%;
        }
    }
}


.content-grid__row__visual {
    img {
        max-width: 100%;
    }

    @include responsive.target(md, xxl) {
        .content-grid__row--img-right & {
            order: 2;
        }
    }
}

.content-grid__row__text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include responsive.target(md) {
        padding: 30px;
    }

    @include responsive.target(lg) {
        padding: 50px;
    }

    @include responsive.target(xl, xxl) {
        padding: 100px;
    }

    :last-child {
        margin-bottom: 0;
    }
}