@use 'libs/styles/responsive';
@use 'config';

.accordion {
    padding: 40px 20px;

    @include responsive.target(lg, xxl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 85px;
        padding: 80px;
    }

    @media screen and (min-width: 1600px) {
        padding: 80px 0;
        margin: auto;
        max-width: config.$max-content-width;
    }

    &.accordion--map {
        padding: 0;
        margin: 0;
        display: block;
        overflow: hidden;

        @include responsive.target(xs, md) {
            height: 0;
            max-height: 70vh;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    &.accordion--visible {
        height: auto !important;
    }
}

.accordion__column {
    @include responsive.target(xs, md) {
        &:not(:last-child) {
            .accordion__item {
                border-bottom: none;
            }
        }
    }
}

.accordion__item {
    padding: 20px 0;
    border-top: 1px solid config.$color-white;

    &:last-child {
        border-bottom: 1px solid config.$color-white;
    }

    @include responsive.target(lg, xxl) {
        border-width: 2px !important;
    }
}

.accordion__item__link {
    &.accordion__item__link--active {
        text-decoration: underline !important;
    }
}

.accordion__item__title {
    .accordion__item__title__link {
        position: relative;
        display: block;
        padding-right: 50px;
        text-decoration: none;

        &::after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            height: 23px;
            width: 23px;
            background: transparent url('/static/media/icons/icon-cta-arrow-right.svg') center no-repeat;
            background-size: contain;
            filter: invert(1);
            transition: transform 0.25s ease-in-out;

            @include responsive.target(lg, xxl) {
                width: 31px;
                height: 31px;
            }
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotate(90deg);
            }
        }
    }

    h2 {
        margin-bottom: 0;
    }
}

.accordion__item__content {
    margin-top: 10px;

    p:last-child {
        margin-bottom: 0;
    }

    &[aria-hidden="true"] {
        display: none;
    }
}