@use 'config';
@use 'libs/styles/responsive';
@use 'fonts';

.chronicles {
    margin: 0 20px;
    border-left: 2px dashed config.$color-white;
    width: 80%;
    max-width: config.$max-content-width;
    margin: auto;

    @include responsive.target(lg, xxl) {
        border-left: none;
    }
}

.chronicles__list {
    padding-left: 60px;

    @include responsive.target(lg, xxl) {
        padding-left: 0;
    }
}

.chronicles__list__item {
    padding-top: 80px;

    @include responsive.target(lg, xxl) {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-bottom: 100px;

        &::before {
            position: absolute;
            right: 0;
            top: 2px;
            bottom: 0;
            width: 0;
            border-left: 2px dashed config.$color-white;
            content: " ";
        }

        .chronicle-item__date {
            padding-right: 100px;

            &::before {
                left: auto;
                right: 0;
            }
        }

        .chronicle-item__text {
            padding-right: 100px;
        }

        .chronicle-item__image {
            transform: translateX(125%);
            max-width: 85%;
            margin-top: -300px;
        }

        &:nth-child(2n - 1) {
            margin-left: 50%;

            &::before {
                right: auto;
                left: -2px;
            }

            .chronicle-item__text {
                padding-right: 0;
                padding-left: 100px;
            }

            .chronicle-item__image {
                transform: translateX(-100%);
                margin-left: -7.5%;
            }

            .chronicle-item__date {
                padding-left: 100px;
                padding-right: 0;

                &::before {
                    left: 0;
                    right: auto;
                }
            }
        }
    }
}

.chronicle-item__image {
    max-width: 100%;
    margin-bottom: 30px;

    @include responsive.target(lg, xxl) {
        order: 3;
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
    }
}

.chronicle-item__date {
    position: relative;
    font-family: fonts.$font-Playfair;
    font-size: 25px;
    font-style: italic;
    margin-bottom: 0;

    &::before {
        position: absolute;
        content: " ";
        left: -60px;
        top: calc(50% + 6px);
        transform: translateY(-50%);
        height: 2px;
        border-top: 2px dashed config.$color-white;
        width: 55px;
    }

    @include responsive.target(lg, xxl) {
        order: 1;
        margin-bottom: 20px;
        font-size: 40px;

        &::before {
            left: 0;
            width: 75px;
        }
    }
}

.chronicle-item__text {
    p:last-child {
        margin-bottom: 0;
    }

    @include responsive.target(lg, xxl) {
        order: 2;
        max-width: 85%;
    }
}