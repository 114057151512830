/*********** Colors ***********/
$color-black: #000000;
$color-white: #ffffff;
$color-gray-footer: #DADADA;
$color-green-1: #138A64;
$color-green-2: #148A64;
$color-blue-1: #0079C2;
$color-red-1: #E63329;

/*************************************/


$font-Playfair: 'Playfair';
$font-Onest: 'Onest';

/*********** Breakpoints ***********/


$max-content-width: 1440px;

$breakpoints: (
  // Breakpoint Definition
  xs: 0,
  // Extra small screen / phone
  sm: 576px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 992px,
  // Large screen / desktop
  xl: 1280px,
  // Extra large screen / wide desktop
  xxl: 1440px,
  // Double extra large screen / wide desktop
);

/******************************/