@use 'libs/styles/responsive';

.two-third-wrapper {
    padding: 0 20px 40px;

    @include responsive.target(xl, xxl) {
        padding: 0 80px 120px;
    }

    @include responsive.target(lg, xxl) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 60px;
    }

    @include responsive.target(xl, xxl) {
        column-gap: 120px;
    }
}

.two-third-wrapper__full {
    flex: 1 0 100%;
}

.two-third-wrapper__large {
    flex: 0 2 calc(66.67% - 60px);

    @include responsive.target(xl, xxl) {
        flex: 0 2 calc(66.67% - 120px); // caused by bigger gap
    }
}

.two-third-wrapper__small {
    flex: 0 0 33.33%;

    @include responsive.target(lg, xxl) {
        text-align: right;
    }
}