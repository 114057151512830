@use 'libs/styles/responsive';
@use 'config';
@use 'fonts';

.full-bleed-visual {
    overflow: auto;
}

.full-bleed-visual__video,
.full-bleed-visual__image {
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: center;
    background-color: config.$color-black;

    @include responsive.target(xs, md) {
        min-height: 70vh;
    }

    @include responsive.target(lg, xxl) {
        height: calc(100vh - 90px);
        max-height: unset;
    }

    @include responsive.target(xl) {
        height: calc(100vh - 123px);
    }

    @include responsive.target(xxl) {
        height: calc(100vh - 141px);
    }
}

.full-bleed-visual--parallax {
    .full-bleed-visual__image {
        width: 100%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;

        img {
            opacity: 0;
        }
    }
}

.full-bleed-visual__description {
    position: relative;
    z-index: 1;
    max-width: 740px;
    padding: 35px 30px;
    margin: -90px 20px 50px;
    border-radius: 20px;
    background-color: config.$color-white;
    box-shadow: 3px 3px 6px rgba(config.$color-black, 0.31);

    @include responsive.target(xl, xxl) {
        margin: -160px 80px 200px 80px;
        padding: 55px 90px;
    }

    &.full-bleed-visual__description--right {
        float: right;
    }
}

.full-bleed-visual__description__text {
    margin-bottom: 0;

    .page-wrapper--green & {
        color: config.$color-green-2;
    }

    .page-wrapper--blue & {
        color: config.$color-blue-1;
    }

    .page-wrapper--red & {
        color: config.$color-red-1;
    }
}