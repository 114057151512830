@use 'libs/styles/responsive';

.spacer {
    &.spacer--small {
        height: 30px;

        @include responsive.target(lg) {
            height: 45px;
        }

        @include responsive.target(xl, xxl) {
            height: 60px;
        }
    }

    &.spacer--medium {
        height: 60px;

        @include responsive.target(lg) {
            height: 90px;
        }

        @include responsive.target(xl, xxl) {
            height: 120px;
        }
    }

    &.spacer--large {
        height: 120px;

        @include responsive.target(lg) {
            height: 180px;
        }

        @include responsive.target(xl, xxl) {
            height: 240px;
        }
    }
}