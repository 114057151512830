@use 'libs/styles/responsive';
@use 'config';

.full-bleed-text {
    padding: 40px 20px;

    @include responsive.target(lg, xxl) {
        padding: 200px 80px;
    }

    &.full-bleed-text--green {
        background-color: config.$color-green-1;
        color: config.$color-white;
    }

    &.full-bleed-text--blue {
        background-color: config.$color-blue-1;
        color: config.$color-white;
    }

    &.full-bleed-text--red {
        background-color: config.$color-red-1;
        color: config.$color-white;
    }

    &.full-bleed-text--white {
        background-color: config.$color-white;
        color: config.$color-black;
    }
}

.full-bleed-text__inner {
    max-width: 970px;
    margin: 0 auto;
}