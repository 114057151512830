@use 'config';
@use 'libs/styles/responsive';

.text-transparent-visual {
    background: url('/static/media/bg-arbon-waves.png') 0 0 repeat;

    &.text-transparent-visual--blue {
        background-color: config.$color-blue-1;
    }

    &.text-transparent-visual--red {
        background-color: config.$color-red-1;
    }

    &.text-transparent-visual--green {
        background-color: config.$color-green-1;
    }
}

.text-transparent-visual__content {
    padding: 75px 20px 20px;
    max-width: 970px;
    box-sizing: content-box;
    margin: auto;

    &.text-transparent-visual__content--white-text {
        * {
            color: config.$color-white;
        }

        .link--arrow::after {
            filter: invert(1);
        }
    }

    @include responsive.target(lg, xxl) {
        padding: 130px 80px 20px;
    }

    @include responsive.target(xl, xxl) {
        width: 632px;
        margin-left: calc((100vw - 970px - 160px) / 2);
    }
}

.text-transparent-visual__image {
    width: 100%;
    display: block;
}