@use 'fonts';
@use 'config';
@use 'libs/styles/responsive';

.contact__form {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.form-row {
    &.form-row--two-cols {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;

        @include responsive.target(xl, xxl) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.form-row--captcha {
        .form-field {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        label {
            display: block;
            margin-bottom: 10px;
        }
    }
}

.form-field {
    display: flex;
    flex-direction: column;
}

.form-field__label {
    font-family: inherit;
    font-weight: fonts.$fw-medium;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 0.2em;
    margin-bottom: 10px;
}

.form-field__input,
.form-row--captcha input[type="text"] {
    background-color: config.$color-white;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: fonts.$fw-regular;
    padding: 12px 10px;
    font-family: inherit;
    max-width: 100%;
    resize: none;
    width: 100%;

    &:focus {
        outline: 1px solid config.$color-black;
    }
}

.form-field__error {
    margin-top: 5px;
    font-size: 14px;
}