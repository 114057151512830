@use 'config';
@use 'app/styles/common';
@use 'libs/styles/responsive';

.page-wrapper--municipality-map {
    .page-body {
        position: relative;
        overflow: hidden;
    }
}

.municipality-filters {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: config.$color-red-1;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    padding: 0 20px;
    box-shadow: 3px 3px 6px rgba(config.$color-black, 0.31);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    @include responsive.target(lg, xxl) {
        min-width: 400px;
        max-width: 35vw;
        padding: 40px 60px 40px 80px;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 0;
        bottom: 0;
        right: inherit;
    }

    .accordion__item {
        padding: 10px 0;

        @include responsive.target(xs, md) {
            &:last-child {
                margin-bottom: 20px;
            }
        }
    }

    .accordion__item__title__link h2 {
        font-size: 20px;
        color: config.$color-white;

        @include responsive.target(lg, xxl) {
            font-size: 25px;
        }
    }

    .accordion__item__content {
        margin-top: 20px;

        a {
            display: block;
            font-size: 16px;
            margin-bottom: 1em;
            color: config.$color-white;
            text-decoration: none;

            @include responsive.target(lg, xxl) {
                font-size: 22px;
            }
        }
    }
}

.municipality-filters__toggler {
    appearance: none;
    border: none;
    background: transparent;
    font: inherit;
    color: config.$color-white;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 500;
    padding: 20px;

    @include responsive.target(lg, xxl) {
        display: none;
    }
}

#municipality-map {
    position: relative;
    background-color: config.$color-green-1;
    height: calc(100vh - 120px);
    margin-top: 30px;
    width: 100%;

    &:not(.map-loaded) {
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            color: config.$color-white;
            transform: translate(-50%, -50%);
            content: attr(data-loading-text);
        }
    }

    @include responsive.target(lg, xxl) {
        margin-top: 0;
        margin-left: 380px;
        width: calc(100% - 380px);
        height: calc(100vh - 90px);
    }

    @include responsive.target(xl) {
        height: calc(100vh - 125px);
    }

    @include responsive.target(xxl) {
        height: calc(100vh - 142px);
    }
}

.municipality-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: config.$color-red-1;
    top: 70px;
    bottom: 0;
    right: 0;
    z-index: 1;
    padding: 25px;
    padding-right: 15px;
    box-shadow: -3px -3px 6px rgba(config.$color-black, 0.31);

    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    max-width: 90%;
    width: 400px;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.25s ease-in-out, opacity 0.15s ease-in-out;

    @include responsive.target(lg, xxl) {
        padding: 55px 45px 55px 55px;
        top: 0;
        bottom: 0;
        left: inherit;
    }

    &.municipality-details--hidden {
        transform: translateX(100%);
        transition: transform 0.25s ease-in-out, opacity 0.25s 0.15s ease-in-out;
        opacity: 0;
    }
}

.municipality-details__closer {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 0;
    width: 30px;
    height: 30px;
    background-color: config.$color-gray-footer;
    border: 1px solid #afafaf;
    border-radius: 20px;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    transition: transform 0.15s ease-out, opacity 0.25s 0.15s ease-out;

    // &::before {
    //     display: block;
    //     content: "\00d7";
    //     font-size: 30px;
    //     line-height: 1;
    //     color: config.$color-black;
    //     width: 100%;
    //     height: 100%;
    // }

    .municipality-details--hidden & {
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        transform: translate(-50%, -50%) scale(1.05);

        &::before,
        &::after {
            background-color: config.$color-black;
        }
    }

    &::before,
    &::after {
        content: " ";
        position: absolute;
        width: 2px;
        height: 18px;
        background-color: lighten(config.$color-black, 25);
        transform-origin: center;
        top: 5px;
        left: 13.5px;
        transition: background-color 0.15s ease-out;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.municipality-details__title {
    padding-right: 10px;
    margin-bottom: 20px;
    color: config.$color-white;
}

.municipality-details__scroller {
    overflow-y: auto;
    padding-right: 10px;
    @include common.niceScrollbar;

    position: absolute;

    top: 25px;
    left: 25px;
    bottom: 25px;
    right: 15px;

    @include responsive.target(lg, xxl) {
        top: 55px;
        left: 45px;
        bottom: 55px;
        right: 25px;
    }
}

.municipality-details__image {
    width: 100%;
    margin-bottom: 20px;
}

.municipality-details__description {
    color: config.$color-white;
}