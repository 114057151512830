@use 'config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';


.footer {
    padding: 45px 20px 55px;
    background-color: config.$color-gray-footer;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @include responsive.target(lg, xxl) {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        padding: 70px 80px;
    }
}

.footer__infos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    flex: 1 1 auto;

    @include responsive.target(lg, xxl) {
        grid-template-columns: repeat(2, 1fr);
        margin-right: 115px;
        gap: 45px;
    }
}

.footer__infos__block {
    max-width: 480px;
}

.footer__logo-container {
    display: flex;
    flex-direction: column;

    @include responsive.target(lg, xxl) {
        gap: 15px;
    }
}

#arb-on-claim {
    margin: auto;

    img {
        width: 180px;
        max-width: 180px;
    }

    @include responsive.target(xs, md) {
        margin-bottom: 40px;
    }
}


.footer__logo {
    max-width: 180px;
    width: 44vw;
    margin: 30px auto 20px;

    @include responsive.target(lg, xxl) {
        margin: 0;
        align-self: center;
    }
}

.footer-info-title {
    font-size: 12px;
    font-weight: fonts.$fw-medium;
    color: config.$color-black;
    letter-spacing: 0.2em;
    line-height: 1.333;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.footer-info-content {
    font-size: 20px;
    font-weight: fonts.$fw-bold;
    line-height: 1.2;
    color: config.$color-black;
    text-decoration: none;
}

.footer__infos__links {
    @include responsive.target(lg, xxl) {
        position: relative;
        padding-top: 70px;
        grid-column: span 2;
        display: flex;
        flex-direction: row;

        &::before {
            position: absolute;
            top: 0;
            content: " ";
            display: block;
            height: 10px;
            width: 100%;
            background: url('/static/media/wave-form.svg') 0 0 repeat-x;
            background-size: contain;
        }
    }
}

.footer__infos__links__cta {
    position: relative;
    display: block;
    font-weight: fonts.$fw-bold;
    font-size: 20px;
    text-decoration: none;
    color: config.$color-black;
    margin: 30px 0;

    @include responsive.target(lg, xxl) {
        display: inline-block;
        margin: 0 30px 0 0;
    }

    @include responsive.target(xl, xxl) {
        margin-right: 125px;
    }

    &::before,
    &::after {
        content: " ";
        display: block;
        height: 10px;
        width: 100%;
        background: url('/static/media/wave-form.svg') 0 0 repeat-x;
        background-size: contain;

        @include responsive.target(lg, xxl) {
            display: none;
        }
    }

    &::before {
        margin-bottom: 20px;
    }

    &::after {
        margin-top: 20px;
    }

    span {
        display: block;
        position: relative;
        padding-left: calc(23px + 15px);
    }

    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 23px;
        aspect-ratio: 1 / 1;
    }
}

.footer__infos__links__nav {
    font-size: 12px;
    font-weight: fonts.$fw-medium;
    letter-spacing: 0.2em;
    line-height: 1.333;
    text-transform: uppercase;
    text-align: center;

    a {
        text-decoration: none;
        color: config.$color-black;
    }

    ul {
        @include responsive.target(lg, xxl) {
            display: flex;
            flex-direction: row;
            gap: 20px
        }

        @include responsive.target(xl, xxl) {
            gap: 40px
        }
    }
}